import React from "react";
import styles from "./TestimonialCard.module.css";

const TestimonialCard = (props) => {
  return (
    <div className={styles.card1}>
      <div className={styles.card2}>
        <div className={styles.card}>
          <img className={styles.logo} src={props.src} />
          <div className={styles.card_title}>{props.header}</div>
          <div className={styles.card_body}>{props.desc}</div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
