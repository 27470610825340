import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import Navbar from "../../Widgets/Navbar/Navbar";
import Footer from "../../Widgets/Footer/Footer";
import HomeCard from "../../Widgets/HomeCard/HomeCard";
import TestimonialCard from "../../Widgets/Testimonials/TestimonialCard";
import "./Home.module.css";
import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { fetchBrokersLinks } from "../../api/apis";

const Home = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentImage, setCurrentImage] = useState("/man1.png");
  const [width, setWidth] = useState(40);
  const [brokers, Brokers] = useState([]);

  useEffect(() => {
    fetchBrokersLinks()
      .then((result) => {
        Brokers(result);
      })
      .catch((error) => {});
  }, []);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    setTimeout(() => {
      if (currentImage === "/testimonial1.png") {
        setCurrentImage("/testimonial2.png");
        alert(currentImage);
      } else {
        setCurrentImage("/testimonial1.png");
      }
      setIsFlipped(false);
    }, 300);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     Img((prevImg) => {
  //       if (prevImg === "/testimonial1.png") {
  //         return "/testimonial2.png";
  //       } else {
  //         return "/testimonial1.png";
  //       }
  //     });
  //   }, 5000);

  //   return () => {
  //     clearTimeout(interval);
  //   };
  // }, []);

  useEffect(() => {
    setWidth(0);
    const interval = setInterval(() => {
      setWidth((prevWidth) => {
        // handleFlip();
        if (prevWidth === 0) {
          return 40;
        } else {
          return 0;
        }
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ height: "80px" }} />
      <div className={styles.fl}>
        <div className={styles.in_txt}>
          We don't provide any kind of paid tips, advisory nor paid training
          courses. Please be cautious of fraudsters Tips Provider, Advisory and
          shell companies. We are not SEBI Registred Advisor. Before any
          investing do self analysis or Consult your Financial advisor before
          any trading decision.
        </div>
        <div className={styles.in_hed}>Welcome to Lucknow Lions</div>
        <div className={styles.in_sub}>Open Free Demat Account</div>
        {/* <img src={process.env.PUBLIC_URL + "/home.png"} /> */}
        {/* <div style={{ height: "40px" }} /> */}
        <img
          style={{ width: "60vw" }}
          src={process.env.PUBLIC_URL + "/home2.jpg"}
        />

        {/* <div className={styles.card}>
          <div className={styles.card_head}>
            <img className={styles.avatar} />
          </div>
          <div className={styles.card_hed}>Rohit Kathait</div>
          <div className={styles.card_body}>
            My name is Rohit Kathait 26 years old and working in Barclays Bank.
            I wanted to thank Saurabh Gandhi for his site. I’ve been trading the
            Stock Market since 2017, I can say I was a mediocre trader at best,
            you know the one (maybe yourself?) good months, bad months and at
            the end breakeven or worse. I found Pivottrading.Net website and
            became interested in trying each and every tool in my trading plan
            specially Algo Trading. After trying everything else in this market
            for 2 years I decided to get serious and really pursue this form of
            trading.
          </div>
        </div> */}

        <div style={{ height: "10s0px" }} />
        <div className={styles.horizontal_line} />
        <div className={styles.hed}>Feature Broker</div>
        <div className={styles.row}>
          <HomeCard
            header="Zerodha"
            src={process.env.PUBLIC_URL + "/kite.jpg"}
            link={brokers.length > 3 && brokers[3].link}
            p1="0 brokerage on Eq Delivery"
            p2="0.03% or Rs 20 per executed order Eq Intraday and Futures."
            p3="Rs 20 Flat per order in Option "
            p4="DP Charges 15.93 per script"
            p5="Account Opening Fees : 200 one time"
            p6="AMC : Free"
            p7="1st year, onward 2nd year 75 + 18% GST every quarter"
            p8="Apps : Kite,Coin"
          />
          <div style={{ width: "20px" }} />'
          <HomeCard
            header="Upstox"
            src={process.env.PUBLIC_URL + "/upstock.jpg"}
            link={brokers.length > 2 && brokers[2].link}
            p1="Flat 20 brokerage on Eq Delivery per order"
            p2="0.05% or Rs 20 per executed order Eq Intraday and Futures."
            p3="Rs 20 Flat per order in Option"
            p4="DP Charges 18.5 per script Account"
            p5="Account Opening Fees : Free"
            p6="Opening Fees : Free AMC : Free 1st year, onward 2nd year 75 +
            18%"
            p7="GST every quarter Apps : Upstox Pro"
          />
          <div style={{ width: "20px" }} />
          <HomeCard
            header="Angel One"
            src={process.env.PUBLIC_URL + "/angelone.jpg"}
            link={brokers.length > 0 && brokers[0].link}
            p1="0 brokerage on Eq Delivery"
            p2="First 30 days 0,after 0.03% or Rs 20 per executed order Eq Intraday and Futures.."
            p3="Rs 20 Flat per order in Option"
            p4="DP Charges 12.5 per script"
            p5="Account Opening Fees : Free"
            p6="AMC : 0 for every years."
            p7="Apps : Fyers"
          />
          <div style={{ width: "20px" }} />
          <HomeCard
            header="Fyers"
            src={process.env.PUBLIC_URL + "/fyer.jpg"}
            link={brokers.length > 1 && brokers[1].link}
            p1="0 brokerage on Eq Delivery"
            p2="0.01% or Rs 20 per executed order Eq Intraday and Futures."
            p3="Rs 20 Flat per order in Option"
            p4="DP Charges 12.5 per script"
            p5="Account Opening Fees : Free"
            p6="AMC : 0 for every years."
            p7="Apps : Fyers"
          />
        </div>

        <div style={{ height: "160px" }} />
        <div className={styles.horizontal_line} />
        <div className={styles.hed}>See What we do</div>

        <div style={{ height: "40px" }} />
        <div className={styles.row}>
          <TestimonialCard
            header="Mission"
            src={process.env.PUBLIC_URL + "/mission.png"}
            desc="Our mission is to maximize wealth for our investors and traders through expert guidance and strategic investments in the stock market."
          />
          <div style={{ width: "30px" }} />
          <TestimonialCard
            header="Vision"
            src={process.env.PUBLIC_URL + "/vission.png"}
            desc="Our vision is to maximize wealth for all our investors & traders, empowering them to achieve their financial goals. Let's reach new heights of success."
          />
          <div style={{ width: "30px" }} />'
          <TestimonialCard
            header="Values"
            src={process.env.PUBLIC_URL + "/value.png"}
            desc="We Research and develop the strategies where you should invest your hard earn money because money you spent is you life time saving."
          />
        </div>

        <div style={{ height: "110px" }} />
        <div className={styles.horizontal_line} />
        <div className={styles.hed}>Our Website Users Speak For Us</div>
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={4000}
        >
          <div>
            <img src="/man1.png" alt="Image" />
            <div className="myCarousel">
              <div className={styles.imageContainer} onClick={handleFlip}>
                <div className={styles.front}>
                  <h3>Rajesh Patel</h3>
                  <p>
                    Lucknow Lions offers a must-have demat account that is
                    seamlessly inegrated and increadibly user-friendly. I can
                    easily manage my investments with their platforms. Highly
                    recomemded this service.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img src="/man2.png" />
            <div className="myCarousel">
              <h3>Arnav Agarwal</h3>
              <p>
                I recently has the plaesure of working with Lucknow Lions and
                have I have to say, I am thoughtly impressed from their speed
                services to their great potential, thi scompany truely stands
                out in the market.
              </p>
            </div>
          </div>
        </Carousel>

        <div style={{ height: "20px" }} />

        <div className={styles.line} style={{ width: `${width}%` }}></div>

        <div style={{ height: "100px" }} />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
