import React from "react";
import styles from "./Footer.module.css";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <nav className={styles.main_nav}>
      <div>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            className={styles.logo}
            alt="Your Logo"
            height="50"
          />
        </Link>
      
      </div>

      
     
              <Link to="/angel_one">Angel One</Link>

      <div className={styles.right}>
        <ul className={styles.menus}>
          <li
            className={
              location.pathname === "/privacy_policy" ? `${styles.active}` : ""
            }
          >
            <Link to="/privacy_policy">Privacy Policy</Link>
          </li>
        </ul>

        <ul className={styles.menus}>
          <li className={location.pathname === "/terms_and_conditions" ? styles.active : ""}>
            <Link to="/terms_and_conditions">Terms & Conditions</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Footer;
