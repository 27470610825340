import React from "react";
import styles from "./HomeCard.module.css";

const HomeCard = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_header}>
        <img className={styles.logo} src={props.src} />
        <span>{props.header}</span>
      </div>
      <div className={styles.card_body}>
        <ul>
          <li>{props.p1}</li>
          <li>{props.p2}</li>
          <li>{props.p3}</li>
          <li>{props.p4}</li>
          <li>{props.p5}</li>
          <li>{props.p6}</li>
          <li>{props.p7}</li>
        </ul>
      </div>
      <a target="_blank" href={props.link}>
        <button className={styles.btn}>Open Now</button>
      </a>
    </div>
  );
};

export default HomeCard;
