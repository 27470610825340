import React, { useState } from "react";
import styles from "./Login.module.css";
import Navbar from "../../Widgets/Navbar/Navbar";
import { host, v } from "../../config";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    passeord: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);


  const validateForm = () => {
    let isValid = true;
    setErrors({
      email: "",
      passeord: "",
    });
    const newErrors = {};

    if (!formData.email) {
      newErrors.name = "Email / Phone Number is required";
      setErrors(newErrors);
      return false;
    }
    const result = parseInt(formData.email, 10);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation pattern
    const phonePattern = /^[0-9]{10}$/; // Pattern for 10-digit phone number

    let a = phonePattern.test(formData.email);

    if (isNaN(result)) {
      if (!emailPattern.test(formData.email)) {
        newErrors.email = "Valid email is required";
        setErrors(newErrors);
        return false;
      }
    } else {
      if (!phonePattern.test(formData.email)) {
        newErrors.email = "Valid Phone Number is required";
        setErrors(newErrors);
        return false;
      }
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (!captchaValid) {
        alert("Please Fill Catcha First");
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(`${host}/api/${v}/visitor/loginS`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        setLoading(false);
        if (response.ok) {
          alert("Signup successful!");
          setFormData({
            name: "",
            password: "",
          });
          alert("COrrect Credentials");
        } else {
          alert(`Signup failed: ${result.message}`);
        }
      } catch (error) {
        alert("Login is Disable for now");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.page_container}>
      <Navbar />

      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.formContainer}>
           

            <div className={styles.left}>
              <div style={{ marginBottom: "30px" }}>
                <div className={styles.h1}>Login here</div>
                <div className={styles.des}>
                  Please Enter your Details below
                </div>
              </div>

              <div>
                <div className={styles.flx}>
                  <label className={styles.label} htmlFor="email">
                    Email / Phone Number:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={errors.email ? styles.errorInput : styles.input}
                  />
                  {errors.email && (
                    <div className={styles.errorMessage}>{errors.email}</div>
                  )}
                </div>

                <div className={styles.flx}>
                  <label className={styles.label} htmlFor="phone">
                    Password:
                  </label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={
                      errors.password ? styles.errorInput : styles.input
                    }
                  />
                  {errors.phone && (
                    <div className={styles.errorMessage}>{errors.phone}</div>
                  )}
                </div>

                <ReCAPTCHA
                  sitekey="6LdKEC8qAAAAAHhkvHdpZ3oIcPRLZe8RrtuocIj3"
                  onChange={(v) => {
                    if (v.length > 20) setCaptchaValid(true);
                  }}
                />
              </div>

              {loading ? (
                "Loading..."
              ) : (
                <button
                  className={styles.submit}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Login
                </button>
              )}

              <div className={styles.last}>
                <div className={styles.label}>
                  Already have an Account?
                  <span className={styles.link}>
                    <Link to="/signup">Signup</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img className={styles.img} src="/login_gif.gif" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
