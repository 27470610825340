// src/SignupForm.js
import React, { useState } from "react";
import Navbar from "../../Widgets/Navbar/Navbar";

const Blank = () => {
  return (
    <div
      style={{
        paddingTop: "60px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Navbar />


    </div>
  );
};

export default Blank;
