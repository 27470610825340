import React from "react";
import Navbar from "../../Widgets/Navbar/Navbar";
import styles from "./AboutUs.module.css";
import Card from "../../Widgets/Card/Card";
import Footer from "../../Widgets/Footer/Footer";
import BigCard from "../../Widgets/BigCard/BigCard";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div style={{ height: "64px" }} />

      <div style={{ padding: "10px" }}>
        <div className={styles.baner}>
          <p className={styles.up_hed}>Welcome to LUCKNOW LIONS</p>
          <div className={styles.ban_content}>
            <div className={styles.ban_child}>
              We are happy to introduce you to Lucknow Lions website. Lucknow
              Lions is the platform where you can open all free demat account
              with tie-ups. Lucknow Lions is a free Demat Account Opening App &
              Website with Serval discount broker and deal in the Online and
              Offline (nearby Lucknow city up to 100 km) account opening
              process.
              <br />
              <br />
              We are the leading service provider in Lucknow, Uttar Pradesh,
              India. Lucknow Lions was founded in 2017 and Saurabh Kumar
              Srivastava is the Owner of the app and website. Lucknow Lions have
              only one office till now which address can be found on the contact
              us page.
            </div>
            {/* <div> */}
              <img
                className={styles.ban_image}
                src={process.env.PUBLIC_URL + "/stock.jpg"}
                alt="Stock"
              />
            {/* </div> */}
          </div>
        </div>
      </div>

      <div style={{ height: "80px" }} />
      {/* <div style={{ height: "80px" }} /> */}
      <div className={styles.main}>
        <div className={styles.main_hed_p}>
          <div className={styles.main_hed}>Founder & CEO</div>
        </div>
        <div style={{ height: "24px" }} />
        <div className={styles.row}>
          <BigCard img="/founder.png" />
        </div>

        <div style={{ height: "80px" }} />
        <div className={styles.main_hed_p}>
          <div className={styles.main_hed}>Board Members</div>
        </div>
        <div style={{ height: "24px" }} />
        <div className={styles.row}>
          <Card name="Saurabh Kumar Srivastava" sh={true} img="/founder.png" />
          <Card name="Pooja Srivastava" sh={false} img="/founder.png" />
        </div>

        <div style={{ height: "20px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
