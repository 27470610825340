import React from "react";
import styles from "./BigCard.module.css";

const BigCard = (props) => {
  return (
    <div className={styles.card}>
      <div style={{ display: "flex", flexWrap:'wrap' }}>
        <div className={styles.card_head}>
          <img
            className={styles.avatar}
            src={process.env.PUBLIC_URL + props.img}
          />
          <div style={{ height: "10px" }} />
          <div className={styles.card_title}>Saurabh Kumar Srivastava</div>
        </div>
        <div className={styles.card_body}>
          Saurabh Kumar Srivastava is the founder of the Lucknow Lions. Started
          the journey from the Youtube channel in September 2017. Before Lucknow
          Lions, he was serving to Vodafone India Ltd in Zonal Office Lucknow.
          <br /><br /><br />
          After Vodafone India Ltd he served as Goverment employees in
          Prayagraaj District Uttar Pradesh. And that period he has entered to
          Investing line sudgestion by his friend and started learnings. He left
          the his job in July 2020 and focuced to provide knowledge and demat
          account services.
        </div>
      </div>
    </div>
  );
};

export default BigCard;
