import React from "react";
import styles from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_head}>
        <img
          className={styles.avatar}
          src={props.sh? process.env.PUBLIC_URL + props.img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxYVq02toSwh-lC00EWX5N8l32zgnj0GlLxo7NfNNFNA&s"}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={styles.card_title}>{props.name}</div>
      </div>
      <div className={styles.card_body}>
        {props.sh
          ?
          <React.Fragment>
            We are happy to introduce you to Lucknow Lions website. Lucknow Lions is the platform where you can open all free demat account with tie-ups. Lucknow Lions is a free Demat Account Opening App & Website  with Serval discount broker and deal in the Online and Offline (nearby Lucknow city upto 100 km) account opening process. <br /> <br />We are the leading service provider in Lucknow,Uttar Pradesh,India. Lucknow Lions is the founded in 2017 and Saurabh Kumar Srivastava is the Owner of the app and website. Lucknow Lions have only one office till now which address can be found in contact us page.
          </React.Fragment>
          :
          <React.Fragment>
          Pooja Srivastava is the executive vice president and chief financial officer for Lucknow Lions. She is responsible for the company’s financial operations, corporate strategy, and investor relations. Pooja Srivastava and her team work to deliver on Lucknow Lions's commitment to help customers around the world save money and live better. <br /> <br />Prior to joining Lucknow Lions in Jan 2022, Pooja Srivastava served as Cubix India as chief financial officer and executive vice president. In this role, she was responsible for leading Cubix India financial operations, as well as its customer operations centers within the India."
     </React.Fragment>
}
      </div>
    </div>
  );
};

export default Card;
