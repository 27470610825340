import React, { useState } from "react";
import styles from "./Signup.module.css";
import Navbar from "../../Widgets/Navbar/Navbar";
import { host, v } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      newErrors.email = "Valid email is required";
      isValid = false;
    }
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(formData.mobileNo)) {
      newErrors.mobileNo = "Valid phone number is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      if(!captchaValid){
        alert("Please Fill Catcha First");
        return;
      }
      setLoading(true);
      const response = await fetch(`${host}/api/${v}/visitor/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      try {

        const result = await response.json();
        setLoading(false);
        if (response.ok) {
          alert("Signup successful!");
          setFormData({
            name: "",
            email: "",
            mobileNo: "",
          });
          navigate("/login");
        } else {
          alert(`Signup failed: ${result.message}`);
        }
      } catch (error) {
        console.log(error)
        console.log(response)
        alert(response);
      }
      finally{
        setLoading(false)
      }
    }
  };

  return (
    <div className={styles.page_container}>
      <Navbar />

      <div className={styles.container}>

        <div className={styles.formContainer}>
          <div className={styles.formContainer}>
            <div className={styles.left}>
              <img className={styles.img} src="/signup_gif.gif" />
            </div>

            <div className={styles.right}>
              <div style={{ marginBottom: "30px" }}>
                <div className={styles.h1}>Get Started</div>
                <div className={styles.des}>Create your Account now</div>
              </div>

              <div>
                <div className={styles.flx}>
                  <label className={styles.label} htmlFor="name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={errors.name ? styles.errorInput : styles.input}
                  />
                  {errors.name && (
                    <div className={styles.errorMessage}>{errors.name}</div>
                  )}
                </div>

                <div className={styles.flx}>
                  <label className={styles.label} htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={errors.email ? styles.errorInput : styles.input}
                  />
                  {errors.email && (
                    <div className={styles.errorMessage}>{errors.email}</div>
                  )}
                </div>

                <div className={styles.flx}>
                  <label className={styles.label} htmlFor="phone">
                    Phone Number:
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleChange}
                    className={
                      errors.mobileNo ? styles.errorInput : styles.input
                    }
                  />
                  {errors.phone && (
                    <div className={styles.errorMessage}>{errors.phone}</div>
                  )}
                </div>

                <ReCAPTCHA
                  sitekey="6LdKEC8qAAAAAHhkvHdpZ3oIcPRLZe8RrtuocIj3"
                  onChange={(v) => {if(v.length>20) setCaptchaValid(true)}}
                />
              </div>

              {loading ? (
                "Loading..."
              ) : (
                <button
                  className={styles.submit}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Sign Up
                </button>
              )}

              <div className={styles.last}>
                <div className={styles.label}>
                  Have an Account?
                  <span className={styles.link}>
                    <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Signup;
